'use strict';

export class SchedulingAnalytics {
    constructor(baseAnalytics) {
        this.baseAnalytics = baseAnalytics;
        this.bootstrap();
    }

    bootstrap() {
        this.scheduleAppointmentComplete();
        this.scheduleAppointmentError();
        this.loginComplete();
        this.loginError();
        this.continueAsGuest();
        this.editAppointmentStart();
        this.editAppointmentComplete();
        this.editAppointmentError();
        this.shareAppointmentStart();
        this.shareAppointmentComplete();
        this.addToCalendarStart();
        this.addToCalendarComplete();
        this.createPatientPortalAccountClick();
        this.scheduleAnotherAppointmentClick();
        this.checkYourSymptomsClick();
        this.videoVisitError();
    }

    scheduleAppointmentComplete() {
        // flash message, appears only once after a successful booking
        if (document.querySelector('[name="make-appointment-success"]')) {
            this.baseAnalytics._sendEvent('schedule_appointment_complete');
        }

        // if a clearstep flow, trigger another vent
        if (document.querySelector('[name="make-appointment-success-clearstep"]')) {
            this.baseAnalytics._sendEvent('clearstep_appointment_complete');
        }

        // if a Duly Now video visit flow, trigger another vent
        if (document.querySelector('[name="make-appointment-success-duly-now-video-visit"]')) {
            this.baseAnalytics._sendEvent('duly_now_video_visit_complete');
        }
    }

    videoVisitError() {
        if (document.querySelector('.videoVisitContainer #patient-information-booking-form #schedulingErrors')) {
            const errorText = document.querySelector('.videoVisitContainer #patient-information-booking-form #schedulingErrors').innerText;

            if (errorText) {
                this.baseAnalytics._sendEvent('duly_now_video_visit_error', {
                    error_message: errorText
                });
            }
        }
    }

    scheduleAppointmentError() {
        // error message comes from backend
        // element only has innerText if error is present
        if (document.querySelector('.main-scheduling-flow #patient-information-booking-form #schedulingErrors')) {
            const errorText = document.querySelector('.main-scheduling-flow #patient-information-booking-form #schedulingErrors').innerHTML;

            if (errorText) {
                this.baseAnalytics._sendEvent('schedule_appointment_error', {
                    error_message: errorText
                })
            }
        }
    }

    loginComplete() {
        // flash message, appears only once after a successful login
        if (document.querySelector('[name="login-success"]')) {
            this.baseAnalytics._sendEvent('login_complete');
        }
    }

    loginError() {
        // flash message, appears only once after a failed login
        if (document.querySelector('[name="login-failure"]')) {
            this.baseAnalytics._sendEvent('login_error', {
                error_message: document.querySelector('.duly-field-helper-text').innerText
            });
        }
    }

    continueAsGuest() {
        const continueAsGuest = document.querySelector('#continue-as-guest');
        if (continueAsGuest) {
            continueAsGuest.addEventListener('click', e => {
                this.baseAnalytics._sendEvent('continue_as_guest');
            });
        }
    }

    editAppointmentStart() {
        const editButton = document.querySelector('.patient-appointment-confirmation-page .detail-content .edit');
        if (editButton) {
            editButton.addEventListener('click', e => {
                this.baseAnalytics._sendEvent('edit_appointment_start');
            });
        }
    }

    editAppointmentComplete() {
        if (document.querySelector('[name="edit-appointment-success"]')) {
            this.baseAnalytics._sendEvent('edit_appointment_complete');
        }
    }

    editAppointmentError() {
        if (document.querySelector('[name="cancel-appointment-failed"]')) {
            const errorMessage = document.querySelector('.location-details span.error').innerText
            this.baseAnalytics._sendEvent('edit_appointment_error', {
                error_message: errorMessage
            });
        }
    }

    shareAppointmentStart() {
        window.addEventListener('shareAppointmentStart', _ => {
            this.baseAnalytics._sendEvent('share_appointment_start');
        });
    }

    shareAppointmentComplete() {
        window.addEventListener('shareAppointmentComplete', e => {
            this.baseAnalytics._sendEvent('share_appointment_complete');
        });
    }

    addToCalendarStart() {
        window.addEventListener('addToCalendarStart', _ => {
            this.baseAnalytics._sendEvent('add_to_calendar_start');
        });
    }

    addToCalendarComplete() {
        window.addEventListener('addToCalendarComplete', e => {
            this.baseAnalytics._sendEvent('add_to_calendar_complete');
        });
    }

    createPatientPortalAccountClick() {
        const createPatientPortalAccount = document.querySelector('#create-patient-portal-btn');
        if (createPatientPortalAccount) {
            createPatientPortalAccount.addEventListener('click', e => {
                this.baseAnalytics._sendEvent('mychart_click');
            });
        }
    }

    scheduleAnotherAppointmentClick() {
        const scheduleAnotherAppointment = document.querySelector('#schedule-another-appointment-btn');
        if (scheduleAnotherAppointment) {
            scheduleAnotherAppointment.addEventListener('click', e => {
                this.baseAnalytics._sendEvent('schedule_another_appointment');
            });
        }
    }

    checkYourSymptomsClick() {
        const tooltipCheckYourSymptomsLinks = document.querySelectorAll("#tip-text a, #tip-text-mobile a");
        tooltipCheckYourSymptomsLinks.forEach(link => {
            link.addEventListener('click', _ => {
                this.baseAnalytics._sendEvent('check_symptoms_tooltip_click');
            });
        });
    }
}